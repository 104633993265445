import React from 'react';
import {navigate} from '@reach/router';
import {withSiteData} from 'react-static';

export default withSiteData(
  class Error404 extends React.Component {
    componentDidMount() {
      const [_, to] = redirects.find(([match, _]) => window.location.href.includes(match)) || [null, null];
      if (to != null) return navigate(to);
    }

    render() {
      return <h1>Sorry, the page you’re looking for couldn’t be found</h1>;
    }
  }
);

const redirects = [
  ['about', '/about'],
  ['contact', '/contact'],
  ['pricing', '/pricing'],
  ['portraits', '/albums/portraits'],
  ['weddings', '/albums/weddings-couples'],
  ['couples', '/albums/weddings-couples'],
  ['family', '/albums/family'],
  ['maternity', '/albums/family'],
  ['lifestyle', '/albums/lifestyle'],
  ['urban', '/albums/urban'],
];
